import React, { useState, useEffect } from 'react';
import { FormControl, TextField, MenuItem } from '@mui/material';
import { fetchCarriersActive } from '../../services/agreementService';

function ScacCodeSelector({ scacCode, onScacCodeChange }) {
  const [carriers, setCarriers] = useState([]);
  const [selectedScacCode, setSelectedScacCode] = useState(localStorage.getItem('selectedScacCode') || '');

  useEffect(() => {
    const loadCarriersActives = async () => {
      try {
        const response = await fetchCarriersActive();
        if (response.data && response.data.length > 0) {
          const uniqueCarriers = Array.from(new Set(response.data));
          setCarriers(['', ...uniqueCarriers]); // Agregar opción vacía al inicio
          console.log(['', ...uniqueCarriers]);
          
          // Check if the selectedScacCode is valid
          if (!selectedScacCode || !uniqueCarriers.includes(selectedScacCode)) {
            const defaultScacCode = uniqueCarriers[0];
            setSelectedScacCode(defaultScacCode);
            localStorage.setItem('selectedScacCode', defaultScacCode);
            onScacCodeChange({ target: { value: defaultScacCode } });
          } else {
            onScacCodeChange({ target: { value: selectedScacCode } });
          }
        }
      } catch (error) {
        console.error("Error loading carriers:", error);
      }
    };

    loadCarriersActives();
  }, []);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setSelectedScacCode(newValue);
    localStorage.setItem('selectedScacCode', newValue);
    onScacCodeChange(event);
  };

  return (
    <FormControl>
      <TextField
        id="outlined-select-carrier"
        select
        label="Scac Code"
        value={selectedScacCode}
        onChange={handleChange}
      >
        {carriers.map((option, index) => (
          <MenuItem key={index} value={option}>
            {option || 'None'} {/* Mostrar 'None' para la opción vacía */}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  );
}

export default ScacCodeSelector;