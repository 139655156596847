import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import { getPdfInvoicePayment } from '../../services/paymentService';
import './InvoiceModal.css';

const InvoiceModal = ({ open, onClose, payment }) => {
    const [pdfUrl, setPdfUrl] = useState('');

    useEffect(() => {
        const fetchPdf = async () => {
            if (open && payment) {
                try {
                    const pdfBlob = await getPdfInvoicePayment(payment.PaymentId);
                    const url = window.URL.createObjectURL(pdfBlob);
                    setPdfUrl(url);
                } catch (error) {
                    console.error('Error fetching invoice PDF:', error);
                }
            }
        };

        fetchPdf();
    }, [open, payment]);

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="invoice-modal-title"
            aria-describedby="invoice-modal-description"
            className="modalBackdrop"
        >
            <Box className="modalBox">
                <Typography id="invoice-modal-title" variant="h6" component="h2">
                    Invoice
                </Typography>
                <Box className="pdfViewer">
                    {pdfUrl ? (
                        <iframe src={pdfUrl} title="Invoice PDF" className="pdfFrame"></iframe>
                    ) : (
                        <p>Loading PDF...</p>
                    )}
                </Box>
                <Box className="modalActions">
                    <Button onClick={onClose} className="closeButton">Close</Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default InvoiceModal;