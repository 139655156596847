import axios from "axios";

const headers = {
  "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIM_OCP_KEY,
  "Content-Type": "application/json",
};
export const fetchPaymentsByScac = async (ScacCode) => {
  try {
    //validate if the ScacCode is not empty
    if (ScacCode === "" || ScacCode === null || ScacCode === undefined)  
      ScacCode = "";
    const config = {
      headers: headers,
      method: "get",
      url: process.env.REACT_APP_GET_PAYMENTS+"?ScacCode="+ScacCode,
    };
    const response = await axios(config);
    if (typeof response.data === "string") {
      try {
        const jsonString = response.data
          .replace(/,\s*}/g, "}")
          .replace(/,\s*]/g, "]");
        const data = JSON.parse(jsonString);
        return data;
      } catch (parseError) {
        return null;
      }
    } else {
      // Si ya es un objeto, devolverlo directamente
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const confirmPayment = async (paymentData) => {
  console.log("Confirming payment:", paymentData);
  try {
    const config = {
      method: "post",
      url: process.env.REACT_APP_CONFIRM_PAYMENTS, // Asegúrate de usar la URL correcta
      headers: {
        "Content-Type": "application/json",
        // Agrega aquí otros headers si son necesarios
      },
      data: paymentData,
    };
    const response = await axios(config);
    console.log("Payment confirmed:", response.data);
    return response.data; // Devuelve los datos de la respuesta
  } catch (error) {
    console.error("Error confirming payment:", error);
    throw error; // Lanza el error para que pueda ser manejado por el llamador
  }
};

export const recalculatePayment = async (paymentData) => {
  console.log("Recalculating payment:", paymentData);
  try {
    const config = {
      method: "post",
      url: process.env.REACT_APP_RECALCULATE_PAYMENTS, // Asegúrate de usar la URL correcta
      headers: {
        "Content-Type": "application/json",
        // Agrega aquí otros headers si son necesarios
      },
      data: paymentData,
    };
    const response = await axios(config);
    console.log("Payment recalculated:", response.data);
    return response.data; // Devuelve los datos de la respuesta
  } catch (error) {
    console.error("Error recalculating payment:", error);
    throw error; // Lanza el error para que pueda ser manejado por el llamador
  }
};

export const getPdfInvoicePayment = async (paymentId) => {
  try {
    console.log("Fetching PDF2...");
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_GET_PDF_PAYMENT}?PaymentId=${paymentId}`,
      responseType: "blob", // Asegúrate de que la respuesta sea un blob
      headers: {
        "Content-Type": "application/pdf",
      },
    };
    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error; // Lanza el error para que el componente pueda manejarlo
  }
};

export const SendEmailPayment = async (paymentData) => {
  try {
    const config = {
      method: "post",
      url: process.env.REACT_APP_SEND_EMAIL_PAYMENTS, // Asegúrate de usar la URL correcta
      headers: {
        "Content-Type": "application/json",
        // Agrega aquí otros headers si son necesarios
      },
      data: paymentData,
    };
    const response = await axios(config);
    return response.data; // Devuelve los datos de la respuesta
  } catch (error) {
    console.error("Error confirming payment:", error);
    throw error; // Lanza el error para que pueda ser manejado por el llamador
  }
};
