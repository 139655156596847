import { Snackbar } from "@mui/material";
import * as React from "react";

const SnackbarInfo = ({ color, message }) => {
    return (
        <>
            <Snackbar
                open={true}
                message={message}
                ContentProps={{
                    sx: {
                        backgroundColor: color,
                        color: 'white',
                    }
                }}
            />
        </>
    );
}

export default SnackbarInfo;