import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  TextField,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  Loop as LoopIcon,
  ArrowForward as ArrowForwardIcon,
  Close as CloseIcon,
  Send as SendIcon,
} from "@mui/icons-material";
import {
  confirmPayment,
  recalculatePayment,
  SendEmailPayment
} from "../../services/paymentService";
import Cookies from "universal-cookie";
import "./PaymentModal.css";

function PaymentModal({
  payment,
  open,
  onClose,
  showSnackbar,
  refreshPayments,
}) {
  const [details, setDetails] = useState([]);
  const [originalDetails, setOriginalDetails] = useState([]);
  const [isDirty, setIsDirty] = useState(false);
  const [confirmClose, setConfirmClose] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingRecalculate, setIsLoadingRecalculate] = useState(false);
  const cookies = new Cookies();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  useEffect(() => {
    if (open && payment) {
      const clonedProducts = payment.products.map((product) => ({
        ...product,
      }));
      setDetails(clonedProducts);
      setOriginalDetails(originalDetails);
      console.log("Payment:", details);
      console.log("Products:", clonedProducts);
      setIsDirty(false); // Reset the dirty state when the modal is opened
    }
  }, [open, payment]);

  const handleSpotsChange = (index, value) => {
    const updatedDetails = [...details];
    updatedDetails[index].quantity = parseInt(value, 10);
    setDetails(updatedDetails);
    setIsDirty(true);
  };

  const handleApprove = async () => {
    setIsLoading(true);
    try {
      const sessionCosmos = cookies.get("sessionCosmos");
      const paymentData = {
        PaymentId: payment.PaymentId,
        products: details,
        user: sessionCosmos.email[0],
      };
      const response = await confirmPayment(paymentData);
      console.log("Payment confirmed:", response);
      if (response.success) {
        setOriginalDetails(details); // Update original details
        setIsDirty(false); // Reset the dirty state
        showSnackbar("green", "Payment confirmed successfully!");
        refreshPayments(); // Refresh the payments table
        onClose(); // Close the modal after confirming the payment
      } else {
        showSnackbar("red", `Error confirming payment: ${response.message}`);
      }
    } catch (error) {
      console.error("Error confirming payment:", error);
      showSnackbar("red", "Error confirming payment");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSendEmail = async () => {
    setIsLoading(true);
    try {
      const sessionCosmos = cookies.get("sessionCosmos");
      const paymentData = {
        PaymentId: payment.PaymentId,
        User: sessionCosmos.email[0],
      };
      const response = await SendEmailPayment(paymentData);
      console.log("Payment confirmed:", response);

      if (response.status) {
        showSnackbar("green", "Email Sended!");
      } else {
        showSnackbar("red", `Error confirming payment: ${response.message}`);
      }
    } catch (error) {
      console.error("Error Sending Email:", error);
      showSnackbar("red", "Error Sending Email");
    } finally {
      setIsLoading(false);
    }
  };

  const handleRequestClose = () => {
    if (isDirty) {
      setConfirmClose(true);
    } else {
      onClose();
    }
  };

  const handleConfirmClose = () => {
    setDetails(originalDetails.map((product) => ({ ...product }))); // Revert changes
    setIsDirty(false); // Reset the dirty state
    setConfirmClose(false);
    onClose();
  };

  const handleCancelClose = () => {
    setConfirmClose(false);
  };

  const handleRecalculate = async () => {
    console.log("Recalculating payment...");
    setIsLoadingRecalculate(true);
    try {
      const sessionCosmos = cookies.get("sessionCosmos");
      console.log("Session:", sessionCosmos);
      const paymentData = {
        PaymentId: payment.PaymentId,
        user: sessionCosmos.email[0],
      };
      const response = await recalculatePayment(paymentData);
      console.log("Payment recalculated:", response);
      if (response.success) {
        setOriginalDetails(response.PaymentRecalculatedResponse.products); // Update original details
        setDetails(response.PaymentRecalculatedResponse.products); // Update the details with recalculated products
        showSnackbar("green", "Payment recalculated successfully!");
        refreshPayments(); // Refresh the payments table
      } else {
        showSnackbar("red", `Error recalculating payment: ${response.message}`);
      }
    } catch (error) {
      console.error("Error recalculating payment:", error);
      showSnackbar("red", "Error recalculating payment");
    } finally {
      setIsLoadingRecalculate(false);
    }
  };

  const handleHistory = () => {
    const historyUrl =
      process.env.URL_SITE_LOCATION +
      "?yard_location=" +
      payment.Location +
      "&scac_code=" +
      payment.scac +
      "&start_date=" +
      payment.startBilling +
      "&end_date=" +
      payment.endBilling +
      "";
    window.open(historyUrl, "_blank");
  };

  const ResetChanges = () => {
    setDetails(payment.products.map((product) => ({ ...product })));
    setIsDirty(false);
  };

  const totalAmount = details.reduce(
    (acc, product) => acc + product.amount * product.quantity,
    0
  );

  if (!payment) return null;

  return (
    <>
      <Modal
        open={open}
        onClose={handleRequestClose}
        BackdropProps={{
          className: "modalBackdrop",
        }}
      >
        <Box className="modalBox">
          <Grid container spacing={2}>
          <Grid item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "right",
              }}>
              <Typography variant="h6" component="h2">{payment.scac}</Typography>
              <Button onClick={handleRequestClose} endIcon={<CloseIcon />} />
            </Grid>
            <Grid item xs={isMobile ? 4 : 3} className="general-info-container">
              <TextField
                label="Cod"
                variant="outlined"
                value={payment.PaymentId}
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                className="fields-general-info bold-value"
              />
            </Grid>
            <Grid item xs={isMobile ? 4 : 3}>
              <TextField
                label="Service"
                variant="outlined"
                value={payment.service}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
                className="fields-general-info"
              />
            </Grid>
            <Grid item xs={isMobile ? 4 : 3}>
              <TextField
                label="Loc/Chas"
                variant="outlined"
                value={payment.Location}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
                className="fields-general-info"
              />
            </Grid>
            <Grid item xs={isMobile ? 4 : 3}>
              <TextField
                label="Fee Type"
                variant="outlined"
                value={payment.type}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
                className="fields-general-info"
              />
            </Grid>
            <Grid item xs={isMobile ? 4 : 3}>
              <TextField
                label="Billing Start"
                variant="outlined"
                value={payment.startBilling}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
                className="fields-general-info"
              />
            </Grid>
            <Grid item xs={isMobile ? 4 : 3}>
              <TextField
                label="Billing End"
                variant="outlined"
                value={payment.endBilling}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
                className="fields-general-info"
              />
            </Grid>
            <Grid item xs={isMobile ? 4 : 3}>
              <TextField
                label="# of Spots"
                variant="outlined"
                value={payment.spotsReserved}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
                className="fields-general-info"
              />
            </Grid>
            <Grid item xs={isMobile ? 4 : 3}>
              <TextField
                label="Total"
                variant="outlined"
                value={totalAmount
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
                className="fields-general-info"
              />
            </Grid>
            <Grid item xs={12} id="container-details">
              <Grid container spacing={2}>
                <Grid item xs={isMobile ? 12 : 3} id="">
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap="16px"
                    id="container-button-details"
                  >
                    <Button
                      variant="contained"
                      id="button-recalculate"
                      endIcon={<LoopIcon />}
                      onClick={handleRecalculate}
                    >
                      {isLoadingRecalculate ? (
                        <CircularProgress size={24} />
                      ) : (
                        "Recalculate"
                      )}
                    </Button>
                    <Button
                      variant="contained"
                      id="button-history"
                      endIcon={<ArrowForwardIcon />}
                      onClick={handleHistory}
                    >
                      Container History
                    </Button>
                    <Button
                      variant="contained"
                      id="button-reset"
                      onClick={ResetChanges}
                    >
                      Revert changes
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={isMobile ? 12 : 9}>
                  <Typography variant="h6" id="titleTable-details">
                    Details
                  </Typography>
                  <Table className="detailsTable">
                    <TableHead className="detailsTableHead">
                      <TableRow>
                        <TableCell>Item</TableCell>
                        <TableCell># of Spots</TableCell>
                        <TableCell>Price</TableCell>
                        <TableCell>Total</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {details.map((detail, index) => (
                        <TableRow key={index} className="detailsTableRow">
                          <TableCell>{detail.ProductName}</TableCell>
                          <TableCell className="detailsTableCell">
                            <TextField
                              type="number"
                              variant="outlined"
                              size="small"
                              value={detail.quantity}
                              disabled={detail.isOverage}
                              onChange={(e) =>
                                handleSpotsChange(index, e.target.value)
                              }
                            />
                          </TableCell>
                          <TableCell>{`$${detail.amount.toFixed(
                            2
                          )}`}</TableCell>
                          <TableCell>{`$${(
                            detail.amount * detail.quantity
                          ).toFixed(2)}`}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ textAlign: "right" }}>
              <Typography variant="h6">
                Total: ${" "}
                {totalAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ textAlign: "right" }}>
              {/* <FormControlLabel
                control={<Checkbox name="needsReview" color="primary" />}
                label="Mark 'Needs Review'"
              /> */}
            </Grid>
            <Grid item xs={12} style={{ textAlign: "right" }}>
              <Button
               variant="contained"
               endIcon={<SendIcon />}
               id="button-send-email"
               onClick={handleSendEmail}
               >
               {isLoading ? <CircularProgress size={24} /> : "Send Email"} 
              </Button>
              <Button
                variant="contained"
                color="success"
                id="button-confirm"
                onClick={handleApprove}
              >
                {isLoading ? <CircularProgress size={24} /> : "Approve"}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Dialog
        open={confirmClose}
        onClose={handleCancelClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Unsaved Changes</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You have unsaved changes. Are you sure you want to close without
            saving?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmClose} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default PaymentModal;