import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  TablePagination,
  useMediaQuery,
} from "@mui/material";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { useTheme } from '@mui/material/styles';
import "./PaymentsTable.css";

function PaymentsTable({ payments, onSelectPayment }) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("startBilling");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleRequestSort = () => {
    const isAsc = orderBy === "startBilling" && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy("startBilling");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getStatusClass = (status, feeType) => {
    if (feeType === "Deposit" && status === "created") {
      return "statusOpen";
    }
    switch (status) {
      case "created":
        return "statusCreated";
      case "completed":
        return "statusCompleted";
      case "succeeded":
        return "statusSucceeded";
      case "confirmed":
        return "statusConfirmed";
      case "open":
        return "statusOpen";
      default:
        return "statusError";
    }
  };

  const getStatusText = (status, feeType) => {
    if (feeType === "Deposit" && status === "created") {
      return "Pending Payment";
    }
    switch (status) {
      case "created":
        return "Audit Req.";
      case "completed":
        return "Paid";
      case "succeeded":
        return "Paid";
      case "confirmed":
        return "Prepare Invoice";
      case "open":
        return "Pending Payment";
      default:
        return status;
    }
  };

  const renderFeeType = (payment) => {
    if (payment.type === "Deposit") {
      return "Deposit";
    }
    if (payment.type === "Overage") {
      return "Overage Storage";
    }
    return `${payment.frequency} Storage`;
  };

  const handleRowClick = (payment) => {
    if (
      payment.Status === "completed" ||
      payment.Status === "succeeded" ||
      payment.Status === "confirmed"
    ) {
      onSelectPayment(payment, "invoice");
    } else {
      onSelectPayment(payment, "audit");
    }
  };

  const sortedPayments = [...(payments || [])].sort((a, b) => {
    let aValue = a[orderBy];
    let bValue = b[orderBy];

    // Manejo de valores nulos
    if (aValue == null) aValue = "";
    if (bValue == null) bValue = "";

    // Conversión de fechas si el campo es 'startBilling'
    if (orderBy === "startBilling") {
      aValue = new Date(aValue);
      bValue = new Date(bValue);
    }

    // Comparación de valores
    if (order === "asc") {
      return aValue > bValue ? 1 : -1;
    } else {
      return aValue < bValue ? 1 : -1;
    }
  });

  // Paginación
  const paginatedPayments = sortedPayments.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className="tableHeadCell">Inv. Status</TableCell>
            {!isMobile && <TableCell className="tableHeadCell">Company</TableCell>}
            <TableCell className="tableHeadCell">Service</TableCell>
            <TableCell className="tableHeadCell">Loc/Chas</TableCell>
            <TableCell className="tableHeadCell">Fee Type</TableCell>
            {!isMobile && <TableCell className="tableHeadCell"># of Spots</TableCell>}
            <TableCell className="tableHeadCell">
              <TableSortLabel
                active={orderBy === "startBilling"}
                direction={orderBy === "startBilling" ? order : "asc"}
                onClick={handleRequestSort}
              >
                Billing Start
              </TableSortLabel>
            </TableCell>
            {!isMobile && <TableCell className="tableHeadCell">Billing End</TableCell>}
            {!isMobile && <TableCell className="tableHeadCell"># of Days</TableCell>}
            {!isMobile && <TableCell className="tableHeadCell">Price</TableCell>}
            {!isMobile && <TableCell className="tableHeadCell">Over. Total</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedPayments && paginatedPayments.length > 0 ? (
            paginatedPayments.map((payment) => {
              const isClickable =
                payment.Status === "completed" ||
                (payment.type === "Regular" && payment.Status === "created") ||
                (payment.type === "Overage" && payment.Status === "created");
              return (
                <TableRow
                  key={payment.PaymentId}
                  hover={isClickable ? true : false}
                  onClick={isClickable ? () => handleRowClick(payment) : null}
                  className={isClickable ? "clickableRow" : ""}
                >
                  <TableCell
                    className={`tableBodyCell ${getStatusClass(
                      payment.Status,
                      payment.type
                    )}`}
                  >
                    {payment.Status === "completed" && (
                      <ReceiptIcon className="statusIcon" />
                    )}
                    {getStatusText(payment.Status, payment.type)}
                  </TableCell>
                  {!isMobile && (
                    <TableCell className="tableBodyCell">
                      {payment.scac}
                    </TableCell>
                  )}
                  <TableCell className="tableBodyCell">
                    {payment.service}
                  </TableCell>
                  <TableCell className="tableBodyCell">
                    {payment.Location}
                  </TableCell>
                  <TableCell className="tableBodyCell">
                    {renderFeeType(payment)}
                  </TableCell>
                  {!isMobile && (
                    <TableCell className="tableBodyCell">
                      {payment.spotsUsed} / {payment.spotsReserved}
                    </TableCell>
                  )}
                  <TableCell className="tableBodyCell">
                    {payment.startBilling}
                  </TableCell>
                  {!isMobile && (
                    <TableCell className="tableBodyCell">
                      {payment.endBilling}
                    </TableCell>
                  )}
                  {!isMobile && (
                    <TableCell className="tableBodyCell">
                      {Math.round(
                        Math.abs(
                          new Date(payment.endBilling) -
                            new Date(payment.startBilling)
                        ) /
                          (1000 * 60 * 60 * 24) + 1
                      )}
                    </TableCell>
                  )}
                  {!isMobile && (
                    <TableCell className="tableBodyCell">
                      $ {payment.amountRegular}
                    </TableCell>
                  )}
                  {!isMobile && (
                    <TableCell className="tableBodyCell">
                      $ {payment.amountOverage}
                    </TableCell>
                  )}
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={12} className="noDataCell">
                No records found.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={payments !== null ? payments.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}

export default PaymentsTable;
