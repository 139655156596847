import axios from "axios";

const headers = {
    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIM_OCP_KEY,
    "Content-Type": "application/json",
};

export const fetchCarriersActive = async () => {
    try {
        const config =
        {
            headers: headers,
            method: 'get',
            url: process.env.REACT_APP_GET_CARRIERS
        }
        const response = await axios(config);
        return response.data
    } catch (error) {
        console.log(error)
    }
};